<template>
  <div>
    <div class="row">
      <div class="col-lg-8 col-md-12">
        <div class="card">
          <div class="card-header">
            <vb-headers-card-header :data="{ title: 'Your Friends' }" />
          </div>
          <div class="card-body"><vb-widgets-lists-20 /></div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="card">
          <div class="card-header">
            <vb-headers-card-header :data="{ title: 'Find other people you know' }" />
          </div>
          <div class="card-body"><vb-widgets-lists-22 /></div>
          <div class="card-body"><vb-widgets-lists-20 /></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-12"></div>
      <div class="col-lg-4 col-md-12"></div>
      <div class="col-lg-4 col-md-12"></div>
    </div>
  </div>
</template>

<script>
import VbHeadersCardHeader from '@/@vb/widgets/Headers/CardHeader'
import VbWidgetsLists20 from '@/@vb/widgets/WidgetsLists/20'
import VbWidgetsLists22 from '@/@vb/widgets/WidgetsLists/22'

export default {
  name: 'VbFriends',
  components: {
    VbHeadersCardHeader,
    VbWidgetsLists20,
    VbWidgetsLists22,
  },
}
</script>
